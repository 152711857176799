import React from 'react';
import './CallToAction.css';

const CallToAction = () => {
  return (
    <section id="call-to-action" className="call-to-action section ">
      <div
        className="section-title1 position-relative text-center mb-5 pb-2 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <h3 className="position-relative d-inline text-primary ps-4">
          Nos Partenaires
        </h3>
        {/* <h2 className="mt-2">Nos Partenaires de Confiance</h2> */}
      </div>
      <div className="cta-container">
        {/* Ligne 1 : Défilement de droite à gauche */}
        <div className="logo-line scroll-right-to-left">
          <div className="logo">
            <img src="/img/Zig.png" alt="Zig Investment" />
            {/* <p>Zig Investment</p> */}
          </div>
          <div className="logo">
            <img src="/img/kolabo.png" alt="Kolabo Tech" />
            {/* <p>Kolabo Tech</p> */}
          </div>
          <div className="logo">
            <img src="/img/metres.png" alt="Metres Store" />
            {/* <p>Partenaire 3</p> */}

          </div>
          <div className="logo">
            <img src="/img/h.png" alt="H-DESIGN" />
            {/* <p>Partenaire 1</p> */}
          </div>
          <div className="logo">
            <img src="/img/ferde.png" alt="Ferde Design" />
            {/* <p>Partenaire 2</p> */}
          </div>
          <div className="logo">
            <img src="/img/pic.png" alt="Light Pic" />
            {/* <p>Partenaire 3</p> */}
          </div>
        </div>

        {/* Ligne 2 : Défilement de gauche à droite */}
        {/* <div className="logo-line scroll-left-to-right">
          <div className="logo">
            <img src="/img/Zig.png" alt="Partenaire 4" />
        
          </div>
          <div className="logo">
            <img src="/img/Zig.png" alt="Partenaire 5" />
           
          </div>
          <div className="logo">
            <img src="/img/Zig.png" alt="Partenaire 6" />
           
          </div>
          <div className="logo">
            <img src="/img/Zig.png" alt="Partenaire 4" />
            
          </div>
          <div className="logo">
            <img src="/img/Zig.png" alt="Partenaire 5" />
        
          </div>
          <div className="logo">
            <img src="/img/Zig.png" alt="Partenaire 6" />
          
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default CallToAction;
