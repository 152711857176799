import React, { useState } from "react";
import "animate.css"; // Pour ajouter des animations

const ServicesSection1 = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
        icon: "fa-money-bill",
        title: "Placement à court terme",
        description:
          "Profitez de placements à court terme avec des rendements compétitifs allant de 5 % à 9 % selon le montant placé.",
        details:
          "Les placements à court terme vous permettent de placer vos fonds pour une durée limitée, tout en bénéficiant de taux d'intérêt compétitifs allant de 5 % à 9 %, selon le montant investi. Nos solutions sont flexibles et adaptées à vos besoins financiers, vous offrant sécurité et rentabilité.",
      },
      
      {
        icon: "fa-book",
        title: "Carnet Bousanm",
        description:
          "Épargnez sans contraintes avec notre Carnet Bousanm : déposez ce que vous avez et retirez quand vous le souhaitez.",
        details:
          "Le Carnet Bousanm est un service conçu pour vous aider à économiser de manière flexible et sans exigences strictes. Vous pouvez déposer les montants que vous souhaitez, selon vos disponibilités, et les retirer à tout moment selon vos besoins. Idéal pour ceux qui souhaitent épargner à leur rythme et garder un accès rapide à leur argent.",
      },
      
    {
      icon: "fa-users",
      title: "Mutuelle",
      description:
        "Une solution solidaire pour des services financiers accessibles et sécurisés.",
      details:
        "La Mutuelle offre des services de microfinance pour aider ses membres à atteindre leurs objectifs financiers. Profitez de prêts, d'épargnes et d'autres avantages communautaires.",
    },
    {
      icon: "fa-handshake",
      title: "Sol",
      description:
        "Un système de transactions journalières pour une gestion pratique et rapide.",
      details:
        "Avec Sol, effectuez des transactions quotidiennes en toute simplicité. Notre système est conçu pour répondre aux besoins des clients tout en garantissant une sécurité optimale.",
    },
    {
      icon: "fa-credit-card",
      title: "Prêt",
      description:
        "Obtenez un financement rapide et flexible pour tous vos projets personnels ou professionnels.",
      details:
        "Nos services de prêt sont conçus pour répondre à vos besoins, que ce soit pour lancer un projet, acheter un bien ou couvrir une dépense imprévue. Profitez de taux compétitifs et d'une gestion simplifiée.",
    },
    {
      icon: "fa-exchange-alt",
      title: "Bousanm Express",
      description:
        "Effectuez des transactions rapides et sécurisées avec Zelle et Natcash.",
      details:
        "Bousanm Express vous permet de transférer de l'argent en toute sécurité grâce à des services tels que Zelle et Natcash. Profitez d'un processus simple et rapide pour envoyer ou recevoir des fonds localement ou internationalement.",
    },
  ];

  const handleReadMore = (service) => {
    setSelectedService(service);
  };

  const closeModal = () => {
    setSelectedService(null);
  };

  return (
    <div className="container-xxl py-5" id="service">
      <div className="container px-lg-5">
        <div className="section-title1 position-relative text-center mb-5 pb-2">
          <h3 className="position-relative d-inline text-dark ps-4">
            Nos Services
          </h3>
          <h4 className="mt-2">Les Solutions que Nous Proposons</h4>
        </div>
        <div className="row g-4">
          {services.map((service, index) => (
            <div
              key={index}
              className={`col-lg-4 col-md-6 wow zoomIn`}
              data-wow-delay={`${0.1 * (index + 1)}s`}
            >
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className={`fa ${service.icon} fa-2x`}></i>
                </div>
                <h4 className="mb-3">{service.title}</h4>
                <p>{service.description}</p>
                <a
                  className="btn px-3 mt-auto mx-auto"
                  onClick={() => handleReadMore(service)}
                >
                  En Savoir Plus
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal avec animation et fermeture au clic en dehors */}
      {selectedService && (
 <div
 className="modal fade show animate__animated animate__fadeInDown d-flex justify-content-center align-items-center"
 style={{
   backgroundColor: "rgba(0, 0, 0, 0.8)",
   position: "fixed",
   top: 0,
   left: 0,
   width: "100vw",
   height: "100vh",
   zIndex: 1050, // Niveau d'affichage
   overflowX: "hidden", // Empêche le débordement horizontal
 }}
 onClick={closeModal}
>
 <div
   className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
   style={{
     maxWidth: "90%", // Réduire la largeur sur mobile
     margin: "0 auto", // S'assurer qu'il est centré horizontalement
   }}
   onClick={(e) => e.stopPropagation()} // Empêche la fermeture lors d'un clic dans le modal
 >
   <div className="modal-content">
     <div className="modal-header">
       <div className="d-flex align-items-center">
         <i
           className={`fa ${selectedService.icon} fa-2x me-3 text-primary`}
         ></i>
         <h5 className="modal-title">{selectedService.title}</h5>
       </div>
       <button
         type="button"
         className="btn-close"
         onClick={closeModal}
       ></button>
     </div>
     <div className="modal-body">
       <p>{selectedService.details}</p>
     </div>
     <div className="modal-footer"></div>
   </div>
 </div>
</div>

    
      )}
    </div>
  );
};

export default ServicesSection1;
