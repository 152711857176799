import React, { useState } from 'react';

function ContactSection() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('loading');

        try {
            // Replace with your backend API endpoint
            const response = await fetch('https://your-backend-api.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setStatus('success');
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            setStatus('error');
            console.error(error);
        }
    };

    return (
        <section id="contact" className="contact section light-background">
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center mt-3 mb-3">
                    <div className="col-lg-7 ">
                        <div className="card p-2 text-center">
                            <h3>Contactez-Nous</h3>
                            <p>Si vous avez des commentaires, Contactez-nous maintenant.</p>

                            <form onSubmit={handleSubmit} className="php-email-form">
                                <div className="row gy-4 p-3">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Votre nom"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder="Votre Email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            placeholder="Sujet"
                                            value={formData.subject}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            rows="6"
                                            placeholder="Message"
                                            style={{ maxHeight: '100px' }}
                                            value={formData.message}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col-12 text-center">
                                        {status === 'loading' && <div className="loading">Loading...</div>}
                                        {status === 'error' && <div className="error-message">Failed to send message. Please try again.</div>}
                                        {status === 'success' && <div className="sent-message">Your message has been sent. Thank you!</div>}

                                        <button type="submit" className="btn btn-sm btn-primary px-5">
                                            <i className='fas fa-paper-plane me-2'></i>
                                            Envoyer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;
