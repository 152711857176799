import React, { useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "aos/dist/aos.css";
import AOS from "aos";

const featuresLeft = [
  {
    id: 1,
    title: "Vérification du solde",
    description:
      "Consultez votre solde instantanément et gardez un œil sur vos finances à tout moment.",
    icon: "bi bi-wallet2",
    delay: 200,
  },
  {
    id: 2,
    title: "Suivi des transactions",
    description:
      "Accédez à l'historique de vos transactions en temps réel, où que vous soyez.",
    icon: "bi bi-graph-up",
    delay: 300,
  },
  {
    id: 3,
    title: "Notifications instantanées",
    description:
      "Recevez des alertes en temps réel pour chaque mouvement sur votre compte.",
    icon: "bi bi-bell",
    delay: 400,
  },
];

const featuresRight = [
  {
    id: 1,
    title: "Gestion des comptes",
    description:
      "Mettez à jour vos informations personnelles en toute sécurité directement depuis l'application.",
    icon: "bi bi-person-circle",
    delay: 200,
  },
  {
    id: 2,
    title: "Sécurité avancée",
    description:
      "Vos données sont protégées grâce à des technologies de pointe pour garantir votre tranquillité d'esprit.",
    icon: "bi bi-shield-lock",
    delay: 300,
  },
  {
    id: 3,
    title: "Support client rapide",
    description:
      "Contactez notre équipe de support directement depuis l'application pour une assistance immédiate.",
    icon: "bi bi-headset",
    delay: 400,
  },
];

const FeatureItem = ({ title, description, icon, delay, alignEnd }) => (
  <div
    className={`feature-item ${alignEnd ? "text-end" : ""} mb-5`}
    data-aos={alignEnd ? "fade-right" : "fade-left"}
    data-aos-delay={delay}
  >
    <div
      className={`d-flex align-items-center ${
        alignEnd ? "justify-content-end" : ""
      } gap-4`}
    >
      {alignEnd && (
        <div className="feature-content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      )}
      <div className="feature-icon flex-shrink-0">
        <i className={icon}></i>
      </div>
      {!alignEnd && (
        <div className="feature-content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      )}
    </div>
  </div>
);

const Features2 = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section id="features-2" className="features-2 section">
      <div className="container  text-center" data-aos="fade-up" data-aos-delay="100">
        <div className="row align-items-center">
         
          <div className="section-title1 position-relative text-center mb-5 pb-2">
          <h3 className="position-relative d-inline text-dark ps-4">
          Fonctionnalités
          </h3>
          <h4 className="mt-2"> Gérez vos finances simplement et efficacement avec notre
          application</h4>
        </div>
          {/* Features Left */}
          <div className="col-lg-4">
            {featuresLeft.map((feature) => (
              <FeatureItem
                key={feature.id}
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                delay={feature.delay}
                alignEnd={true}
              />
            ))}
          </div>
          <div
            className="col-lg-4 text-center"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
              <div className="phone-mockup text-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/phone-app-screen.png`}
              alt="Application Mobile"
              className="img-fluid"
            />
          </div>
          </div>
          {/* Features Right */}
          <div className="col-lg-4">
            {featuresRight.map((feature) => (
              <FeatureItem
                key={feature.id}
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                delay={feature.delay}
                alignEnd={false}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features2;
