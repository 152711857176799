import React from "react";
//import "./Footer.css"; // Importez ici votre fichier CSS si nécessaire
const footerTexts = {
  contact: {
    title: "Get In Touch",
    address: "123 Street, New York, USA",
    phone: "+012 345 67890",
    email: "info@example.com",
  },
  links: {
    title: "Popular Link",
    items: [
      "About Us",
      "Contact Us",
      "Privacy Policy",
      "Terms & Condition",
      "Career",
    ],
  },
  gallery: {
    title: "Project Gallery",
    images: [
      "img/portfolio-1.jpg",
      "img/portfolio-2.jpg",
      "img/portfolio-3.jpg",
      "img/portfolio-4.jpg",
      "img/portfolio-5.jpg",
      "img/portfolio-6.jpg",
    ],
  },
  newsletter: {
    title: "Newsletter",
    description:
      "Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu",
    placeholder: "Your Email",
  },
  copyright: {
    siteName: "Your Site Name",
    designer: "HTML Codex",
    distributor: "ThemeWagon",
    menu: ["Home", "Cookies", "Help", "FQAs"],
  },
};
const Footer1 = () => {
  return (
    <div
      className="container-fluid bg-success text-light footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container">
        <div className="row ">
          {/* Section "Nous contacter" */}
          <div className="col-md-6 col-lg-4">
            <h5 className="text-white mb-4">Nous contacter</h5>
            <p className="text-white">
              <i className="fa fa-map-marker-alt me-3 text-white"></i>111, Rue 6
              J, Cap-Haitien, Haïti
            </p>
            <p className="text-white">
              <i className="fa fa-phone-alt me-3 text-white"></i>+509 5565-7272 | +1 (954) 831-9103
            </p>
            <p className="text-white">
              <i className="fa fa-envelope me-3 text-white"></i>
              zig.bousanm@gmail.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-instagram"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>

          {/* Section "Liens utiles" */}
          <div className="col-md-6 col-lg-3">
            <h5 className="text-white mb-4">Liens utiles</h5>
            <a className="btn btn-link" href="">
              À propos de nous
            </a>
            <a className="btn btn-link" href="">
              Contactez-nous
            </a>
            <a className="btn btn-link" href="">
              Politique de confidentialité
            </a>
            <a className="btn btn-link" href="">
              Conditions générales
            </a>
          </div>

          {/* Section "Galerie de projets" */}
          <div className="col-md-6 col-lg-4 me-5">
            <div className="row g-2">
            <h5 className="text-white mb-4">Bousanm</h5>
              <p className="text-white mt-0">Notre plateforme assure la protection de vos données grâce à des 
                systèmes de sécurité avancés. Optimisée pour la performance,
                 elle garantit des transactions rapides et une navigation fluide 
                 pour une expérience fiable et sécurisée...</p>
            </div>
          </div>
        </div>
      </div>

      {/* Section Copyright */}
      <div className="container d-flex justify-content-center align-items-center">
        <div className="text-center text-white">
          <p>
            &copy;{" "}
              Bousanm 2024
            , Tous droits réservés. Conçu par{" "}
            <a className="border-bottom text-white" href="">
              KOLABO TECH
            </a>
          </p>
        </div>
      </div>
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up"></i></a>

    </div>
  );
};

export default Footer1;
