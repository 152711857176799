import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FAQSection from '../landing/FAQSection';
import ContactSection from '../landing/ContactSection';
import './home.css';
import PortfolioSection from '../landing/PortfolioSection';
import Header1 from '../landing/Header1';
import AboutSection1 from '../landing/AboutSection1';
import ServicesSection1 from '../landing/ServicesSection1';
import Features2 from '../landing/Features2';
import CallToAction from '../landing/CallToAction';
import Footer1 from '../landing/Footer1';

function Home() {
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        // Simulation de chargement de page (exemple : 2 secondes)
        const timer = setTimeout(() => setLoading(false), 1500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!loading && location.state?.scrollToContact) {
            // Si la page est prête et l'état indique qu'il faut scroller vers "contact"
            const timeout = setTimeout(() => {
                const contactElement = document.getElementById("contact");
                if (contactElement) {
                    // Défilement direct vers la section "contact" avec un décalage
                    window.scrollTo({
                        top: contactElement.offsetTop - 70,  // Ajuste le décalage si nécessaire
                        behavior: 'smooth',
                    });
                }
            }, 300);  // Attendez un peu après la fin du chargement

            return () => clearTimeout(timeout);
        }
    }, [loading, location.state]);  // Surveillez `loading` et `location.state` pour déclencher le scroll

    return (
        
    <div class="container-xxl bg-white p-0 w-100">
            {loading ? (
                <div className="loader-containerh w-100">
                    <div className="loaderh"></div>
                    <img
                        src="/img/logo.png"
                        style={{ width: '70px', height: '70px' }}
                        alt="Logo"
                        className="loader-logo"
                    />
                </div>
            ) : (
                
    <>
                    <Header1 />
                        <AboutSection1 />
                        <Features2 />
                        <ServicesSection1 />
                        <PortfolioSection />
                        <FAQSection />
                        <ContactSection />
                        <CallToAction />
                        <Footer1 /> 

                </>
            )}
        </div>
    );
}

export default Home;
