import React, { useState, useEffect } from "react";
import "./Lightbox.css";

const Lightbox = ({ image, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader
  const [isAnimating, setIsAnimating] = useState(false); // Animation

  // Désactiver le défilement quand la lightbox est ouverte
  useEffect(() => {
    const body = document.body;
    body.style.overflow = 'hidden'; // Désactive le défilement

    // Nettoyage, réactiver le défilement lorsque la lightbox est fermée
    return () => {
      body.style.overflow = ''; // Réactive le défilement
    };
  }, []);

  // Fermer la lightbox lors du clic sur la superposition
  const handleOverlayClick = () => {
    onClose(); // Appelle la fonction pour fermer la lightbox
  };

  const handleNext = (e) => {
    e.stopPropagation(); // Empêche la fermeture de la lightbox
    setIsAnimating(true); // Début de l'animation
    setIsLoading(true); // Affiche le loader
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % image.images.length); // Passe à l'image suivante
      setIsAnimating(false); // Fin de l'animation
      setIsLoading(false); // Cache le loader
    }, 500); // Durée de l'animation (500ms)
  };

  const handlePrev = (e) => {
    e.stopPropagation(); // Empêche la fermeture de la lightbox
    setIsAnimating(true); // Début de l'animation
    setIsLoading(true); // Affiche le loader
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + image.images.length) % image.images.length
      ); // Revenir à l'image précédente
      setIsAnimating(false); // Fin de l'animation
      setIsLoading(false); // Cache le loader
    }, 500); // Durée de l'animation (500ms)
  };

  return (
    <>
      <div className="lightbox-overlay" onClick={handleOverlayClick}>
        <div className="lightbox-content">
          {/* Loader animé */}
          {isLoading && (
            <div className="loader-containerl">
              <div className="loaderl"></div>
              <img
                src="/img/logo1.png"
                alt="Logo"
                className="loader-logo"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
          )}

          {/* Animation pour la transition */}
          <img
            src={image.images[currentIndex]}
            alt={image.alt}
            className={`lightbox-image ${isAnimating ? "fade" : ""}`}
            onLoad={() => setIsLoading(false)}
          />

          {/* Texte descriptif sous l'image */}
          <p className="lightbox-caption">{image.descripion}</p>
        </div>
        <button className="lightbox-prev" onClick={handlePrev}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <button className="lightbox-next" onClick={handleNext}>
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </>
  );
};

export default Lightbox;
