// AppRoutes.js
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from '../page/home/index';
import NotFound from "../400/NotFound";
function AppRoutes() {
  return (
    <body class="layout-fixed sidebar-expand-lg bg-body-tertiary">
      <div class="app-wrapper">
      <Router>
                  <Switch>
                    <Route path="/" component={Home} exact />
                        {/* Route 404 */}
                        <Route
                          path="*"
                          component={NotFound }
                        />
                  </Switch>
      </Router>
    </div>
    </body>
  );
}

export default AppRoutes;
